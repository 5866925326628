import React, { useState, useEffect } from "react"
import { useAppDispatch } from '../../app/hooks'
import WebviewEvent from "../../components/webview/event/WebviewEvent"
import { Box, Typography } from "@mui/material"
import { useAppSelector } from "../../app/hooks"
import { getFuneralState } from "../../features/funeral/funeralSlice"
import { getRfmEditState, setSelectedEvent } from '../../features/rfmEdit/rfmEditSlice'
import { getCurrentWidthDimension } from "../../constants"

function Events() {
  const dispatch = useAppDispatch()
  const funeralState = useAppSelector(getFuneralState)
  const rfmEditState = useAppSelector(getRfmEditState)
  const { events } = funeralState
  const { event_details, selectedEvent } = rfmEditState
  const [upComingEvents, setUpcomingEvents] = useState<any[]>([])
  const [pastEvents, setPastEvents] = useState<any[]>([])

  const [selectedRadio, setSelectedRadio] = useState('')

  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }

    window.addEventListener('resize', updateDimension);
  }, [screenWidth])

  useEffect(() => {
    setSelectedRadio(selectedEvent)

  }, [])

  useEffect(() => {
    let date = new Date().setHours(0, 0, 0, 0)
    let curr = []
    let past = []

    for (var item of events) {
      if (new Date(item.endDate).getTime() < date) {
        past.push(item)
      } else {
        curr.push(item)
      }
    }

    function compareEvents(event1: any, event2: any) {
      // Compare startDates
      if (event1.startDate < event2.startDate) {
        return -1;
      }
      if (event1.startDate > event2.startDate) {
        return 1;
      }

      // If startDates are equal, compare startTimes
      if (event1.startTime < event2.startTime) {
        return -1;
      }
      if (event1.startTime > event2.startTime) {
        return 1;
      }

      return 0; // If both startDate and startTime are equal
    }

    curr.sort(compareEvents);

    setUpcomingEvents(curr)

    past.sort(compareEvents);

    setPastEvents(past)
  }, [events])

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value)
    dispatch(setSelectedEvent(event.target.value))
  }

  return (
    <Box sx={{ minHeight: '500px' }}>
      {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}
      <Box sx={{ py: 2 }}>
        {upComingEvents.length > 0 ? (
          <Typography component={"div"} variant={"body2"} className="sectionTitle">
            Events
          </Typography>
        ) : (
          <Box>
            <Typography>No upcoming events</Typography>
          </Box>
        )}
        {upComingEvents.map((item, index) => {
          return (
            <Box key={index} sx={{ py: 2 }}>
              <WebviewEvent event={item} isOver={false} type={"event"} handleRadioChange={handleRadioChange} selectedRadio={selectedRadio} />
              {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}
            </Box>
          )
        })}
      </Box>

      <Box sx={{ py: 2 }}>
        {pastEvents.length > 0 ? (
          <Typography component={"div"} variant={"body2"} className="sectionTitle">
            Past Events
          </Typography>
        ) : (
          <></>
        )}
        {pastEvents.map((item, index) => {
          return (
            <Box key={index} sx={{ py: 2 }}>
              <WebviewEvent event={item} isOver={true} type={"event"} />
              <Typography sx={{ py: 2 }} component={"div"} variant={"body2"} className="hr"></Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default Events
