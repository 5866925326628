import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import "react-datepicker/dist/react-datepicker.css";
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Modal from "@mui/material/Modal"

import { CheckedIcon, Icon } from '../../base/Checkbox/CustomCheckbox';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../../base/Select/CustomSelect';
import MenuItem from '@mui/material/MenuItem';
import { getDateInRange, formatDate } from '../../../constants';
import Button from '../../base/Button/Button';
import Toast from '../../base/Toast/Toast';
import { getUserState, toggleLoginModal } from '../../../features/user/userSlice';
import { useGetRsvpQuery, useInsertUpdateRsvpMutation } from '../../../features/rsvp/rsvpApi';
import LoginModal from '../../login/LoginModal';

interface RsvpProps {
  event: any
}

const attending = ['Attending', 'Not Attending']

const Rsvp: React.FC<RsvpProps> = (props) => {
  const { id } = useParams()
  const { event } = props
  const dispatch = useAppDispatch()
  const userState = useAppSelector(getUserState)
  const [toast, setToast] = useState({
    message: '',
    open: false,
    type: 'success',
    handleClose: () => {setToast(prev => ({...prev, open:false}))}
  })

  const { isLoggedIn, eventInvites, user } = userState
  const [isInvited, setInvited] = useState(false)
  const [range, setRange] = useState<any[]>([])
  const [selectedDate , setSelectedDate] = useState<string[]>([])
  const [isAttending, setIsAttending] = useState('Attending')
  const [isChangeResponse, setIsChangeResponse] = useState(false)
  const [submittingMessage, setSubmittingRsvp] = useState(false)

  const handleOpenLoginModal = () => dispatch(toggleLoginModal(""))
  const handleCloseLoginModal = () => dispatch(toggleLoginModal(""))

  const [crudRsvp] = useInsertUpdateRsvpMutation()
  const { data, refetch } = useGetRsvpQuery(id, {refetchOnMountOrArgChange: true})

  useEffect(() => {
    if(event.startDate && event.endDate) {
      let rv = getDateInRange(event.startDate, event.endDate)
      setRange(rv)
      const rsvpData = data?.find((elem: any) => elem?.email === user?.email && elem?.eventId === event.id && elem?.funeralId === parseInt(id as string, 10));
      if (rsvpData) {
        setSelectedDate(rsvpData?.dateAttending ? (rsvpData?.dateAttending as string)?.split(',') : [])
        setIsAttending(rsvpData?.attending === 1 ? 'Attending' : 'Not Attending')
      }
    }

    const index = eventInvites.findIndex((item) => {
      return event.id === item.eventId
    })
    index < 0 ? setInvited(false) : setInvited(true)
  }, [event, eventInvites, data, id])

  useEffect(() => {
    if (isLoggedIn && submittingMessage && userState.toggleLoginModal === false) {
      submitRsvp();
    }
  }, [submittingMessage, userState, isLoggedIn])

  useEffect(() => {
    if (data && data?.length > 0 ) {
      const rsvpData = data.find((elem: any) => elem?.email === user?.email && elem?.eventId === event.id && elem?.funeralId === parseInt(id as string, 10));
      if (rsvpData) {
        setIsChangeResponse(true);
      } else {
        setIsChangeResponse(false)  
      }
    } else {
      setIsChangeResponse(false)
    }
  }, [data, event, id])

  const handleAttendChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setIsAttending(value)
  }

  const handleChange = (event: SelectChangeEvent<typeof selectedDate>) => {
    const {
      target: { value },
    } = event;
    setSelectedDate(
      prev =>
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleRsvp = () => {
    if (isLoggedIn) {
      submitRsvp();
    } else {
      if (isInvited) {
        setToast({
          ...toast,
          open: true,
          message: "You are not invited to this event.",
          type: 'error'
        })
      } else {
        handleOpenLoginModal();
        setSubmittingRsvp(true);
      }
    }
  }
  const submitRsvp = async () => {
    try {
      let body = {
        attending: isAttending,
        dateRange: selectedDate, //start + '-' + end,
        funeralId: id,
        eventId: event.id
      }
      let rv = await crudRsvp(body).unwrap()
      refetch();

      if(rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: 'success'
        })
      }
      
    } catch(e: any) {
      console.log("error", e.data.message)
      setToast({
        ...toast,
        open: true,
        message: "An error occurred. Please try again or contact our support staff!",
        type: 'error'
      })
    }
  }

  return (
    <>
      { range.length > 1 ? (
        <Box>
          <Toast {...toast}/>
          <CustomSelect
            name="date"
            sx={{mb:2, height:{xs: '45px'}}}
            size='small'
            labelId="action"
            value={isAttending}
            onChange={handleAttendChange}
            fullWidth
            className='selectStyle'
            style={{background: isChangeResponse ? 'var(--TLC-web-grey)' : 'var(--TLC-white)'}}
          >
            {attending.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'var(--TLC-web-grey)',
                    '&:hover': {
                      backgroundColor: 'var(--TLC-web-grey)',
                    },
                  },
                }}
              >
                {item}
              </MenuItem>
            ))}
          </CustomSelect>
          {
            isAttending === 'Attending' ? (
              <CustomSelect
                  sx={{mb:2, height:{xs: '45px'}}}
                  multiple
                  size='small'
                  value={selectedDate}
                  onChange={handleChange}
                  renderValue={(selected: any) => selected.join(', ')}
                  fullWidth
                  className='selectStyle'
                  style={{background: isChangeResponse ? 'var(--TLC-web-grey)' : 'var(--TLC-white)'}}
                >
                  {range && range.map((item, index) => (
                    <MenuItem 
                      key={index} 
                      value={item}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'var(--TLC-web-grey)',
                          '&:hover': {
                            backgroundColor: 'var(--TLC-web-grey)',
                          },
                        },
                      }}
                    >
                      <Checkbox  icon ={<Icon />} checkedIcon={<CheckedIcon />} checked={selectedDate.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </CustomSelect>
            ) : <></>
          }
          <Button className='btnPrimary' sx={{mb:4, height: {xs:'45px'}}} onClick={handleRsvp} fullWidth>
            {isChangeResponse ? "Change Response" : "RSVP"}
          </Button>
        </Box>
      ) : (
        <Box>
          <Toast message={toast.message} open={toast.open} type={toast.type} handleClose={toast.handleClose}/>
          <CustomSelect
          className='selectStyle'
            name="date"
            sx={{mb:2, height:{xs: '45px'}}}
            size='small'
            labelId="action"
            value={isAttending || ""}
            onChange={handleAttendChange}
            fullWidth
          >
            {attending.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'var(--TLC-web-grey)',
                    '&:hover': {
                      backgroundColor: 'var(--TLC-web-grey)',
                    },
                  },
                }}
              >
                {item}
              </MenuItem>
            ))}
          </CustomSelect>
          <Button className='btnPrimary' sx={{mb:4, height: {xs:'45px'}}} onClick={handleRsvp} fullWidth>
            {isChangeResponse ? "Change Response" : "RSVP"}
          </Button>
        </Box>
      )}
      <Modal open={userState.toggleLoginModal} onClose={handleCloseLoginModal}>
        <LoginModal handleClose={handleCloseLoginModal} eventId={event.id} />
      </Modal>
    </>
  )
  
}

export default Rsvp