import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { InputLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomTextField from '../base/Textfield/CustomTextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';
import Modal from "@mui/material/Modal"

import { Icon, CheckedIcon } from '../../components/base/Checkbox/CustomCheckbox';

import styles from './LoginModal.module.css'
import Button from '../base/Button/Button'
import { useGetMeMutation } from '../../features/user/userGqlAPI';
import { useLoginMutation, useRegisterMutation } from '../../features/auth/authGqlApi'
import { useBackendLoginMutation, useRegisterUserMutation } from '../../features/auth/authRestApi';
import Toast from '../base/Toast/Toast';
import { debounce, validateEmail, validateName, validateTel, validatePw, validateCountryCode } from '../../constants';
import { useUpdateEventGuestMutation, useUpdateMainGuestMutation } from '../../features/guestlist/guestlistRestApi';


interface props {
  onClick: (x: number) => void;
  handleClose: () => void;
  eventId?: string | null;
}

const RegisterForm: React.FC<props> = (props) => {
  const {
    onClick,
    handleClose,
    eventId = null
  } = props;
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [registerUser, { data, isLoading, isError, error, isSuccess }] = useRegisterMutation();
  const [updateMainGuest] = useUpdateMainGuestMutation()
  const [updateEventGuest] = useUpdateEventGuestMutation()
  const [loginUser, loginResult] = useLoginMutation();
  const [backendLogin, result] = useBackendLoginMutation()
  const [showPassword, setShowPassword] = useState(false);
  const [pdpaChecked, setPdpaChecked] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [agreeModal, setAgreeModal] = useState(false);
  const [isRegisterSuccessed, setIsRegisterSuccessed] = useState(false);
  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);
  const [errors, setErrors] = useState({
    email: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
    password: false,
    PDPAchecked: false,
    countryCode: false,
  })
  const [toast, setToast] = useState({
    open: false,
    type: 'success',
    message: '',
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })
  const [registerForm, setRegisterForm] = useState({
    email: '',
    lastName: '',
    firstName: '',
    password: '',
    phoneNumber: '',
    countryCode: '',
    PDPAchecked: false,
  })
  const [registerFail, setRegisterFail] = useState({
    error: false,
    message: ''
  })
  const [getMe, getActiveResult] = useGetMeMutation()

  const [register] = useRegisterUserMutation()

  const validateSingleField = (obj: { key: string, value: string }) => {
    const { key, value } = obj
    let rv
    if (key === 'firstName' || key === 'lastName') {
      rv = validateName(value.trim())
    }
    if (key === 'email') {
      rv = validateEmail(value.trim())
    }
    if (key === 'password') {
      rv = validatePw(value.trim())
    }
    if (key === 'phoneNumber') {
      rv = validateTel(value.trim())
    }
    if (key === 'countryCode') {
      if (value) {
        rv = validateCountryCode(value)
      } else rv = true
    }
    if (key === 'PDPAchecked') {
      rv = !value ? false : true;
    }

    if (!rv) {
      setErrors(prev => ({ ...prev, [key]: true }))
    } else {
      setErrors(prev => ({ ...prev, [key]: false }))
    }
  }

  const debounceHandler = useCallback(
    debounce(validateSingleField)
    , []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value.trim()

    if (key === 'email') {
      setRegisterFail({
        error: false,
        message: ''
      })
    }

    setRegisterForm({
      ...registerForm,
      [key]: value
    })

    debounceHandler({ key, value })
  }

  const handleChangeCheckbox = (key: string, value: boolean) => {
    if (key === 'PDPAchecked') {
      debounceHandler({ key, value })
      setPdpaChecked(value)
      setRegisterForm({
        ...registerForm,
        [key]: value
      })
    }
  }

  const handleRegister = async () => {
    const hasError = validate(registerForm)
    if (hasError) {
      return
    }

    setLoading(true)
    try {
      let data: any = { ...registerForm, phoneNumber: `${registerForm.countryCode} ${registerForm.phoneNumber}` }
      delete data.countryCode

      let rv = await register(data).unwrap()

      setConfirmModal(true)

      setRegisterFail({
        error: false,
        message: ''
      })

      if (rv.data.registerCustomerAccount.success) {
        setIsRegisterSuccessed(true);
      }

    } catch (e: any) {

      setRegisterFail({
        error: true,
        message: e.data.message
      })

      setToast({
        ...toast,
        open: true,
        message: e.data.message,
        type: 'error'
      })
      setLoading(false)
    }
  }

  const validate = (item: any) => {
    let hasError = false
    for (const [key, value] of Object.entries(item)) {
      switch (key) {
        case "firstName":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.firstName ? hasError = true : setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "lastName":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.lastName ? hasError = true : setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "email":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.email ? hasError = true : setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "password":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.password ? hasError = true : setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "PDPAchecked":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.PDPAchecked ? hasError = true : setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        default:
          break;
      }
    }
    return hasError
  }

  const handleBackendLogin = () => {
    if (isRegisterSuccessed) {
      backendLogin({ email: registerForm.email, password: registerForm.password })
    }
  }
  const handleCloseConfirmModal = () => setConfirmModal(false);
  const handleCloseAgreeModal = () => setAgreeModal(false);

  const addUserToGuest = () => {
    const guest = {
      firstName: registerForm?.firstName,
      lastName: registerForm?.lastName,
      phoneNumber: registerForm?.phoneNumber,
      email: registerForm?.email
    }
    if (eventId) {
      handleAddGuestToEvent(guest)
    } else {
      handleAddGuestToMain(guest)
    }
  }

  const handleAddGuestToEvent = async (guest: any) => {
    try {
      let body = {
        type: 'add',
        funeralId: id,
        eventId: eventId,
        guest: guest
      }
      let rv = await updateEventGuest(body).unwrap()

      if (rv.status === 'error') {
        setToast({
          ...toast,
          message: rv.message,
          type: 'error',
          open: true
        })
      } else {
        setToast({
          ...toast,
          message: rv.message,
          type: 'success',
          open: true
        })
      }
      return true
    } catch (e) {
      console.error('rejected', e);
    }
  }

  const handleAddGuestToMain = async (guest: any) => {
    try {
      let body = {
        type: 'add',
        funeralId: id,
        guest: guest
      }
      let rv = await updateMainGuest(body).unwrap()

      if (rv.status === 'error') {
        setToast({
          ...toast,
          message: rv.message,
          type: 'error',
          open: true
        })
      } else {
        setToast({
          ...toast,
          message: rv.message,
          type: 'success',
          open: true
        })
      }
      return true
    } catch (e) {
      console.error('rejected', e);
    }
  }

  //Register useEffect
  useEffect(() => {
    if (result.isSuccess && result.data.user_meta) {
      loginUser({ email: registerForm.email, password: registerForm.password })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.isSuccess])

  //loginUser useEffect
  useEffect(() => {
    if (loginResult.isSuccess) {
      getMe(null)
      addUserToGuest();
      handleClose()
    }
    if (loginResult.isError) {
      console.log("error", error, isError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResult.isLoading]);

  return (
    <>
      <Toast {...toast} />
      <div className={styles.registerModal}>
        <Card className={styles.card}>
          <Typography sx={{ pt: 3 }} className='sectionTitle'>Register to Contribute</Typography>
          <Typography sx={{ pt: 2 }} component={'div'}>
            <span className={`${styles.title} ${styles.bold}`}>First time login in to TheLifeCelebrant?</span>
          </Typography>
          <Box sx={{ pt: 2 }} style={{ display: 'flex' }}>
            <div className={styles.blueSq}></div>
            <span className={styles.text} style={{ marginLeft: 10 }}>Required fields to be filled.</span>
          </Box>
          <Box sx={{ mt: 4 }}>
            <InputLabel sx={{ mb: 1 }} htmlFor="email">Email</InputLabel>
            <CustomTextField error={errors.email} helperText={errors.email ? 'Requires a valid email' : ''} onChange={handleChange} size="small" sx={{ mb: 2 }} required id="email" name="email" placeholder='Email' fullWidth className={styles.input} />

            <InputLabel sx={{ mb: 1 }} htmlFor="password">Password</InputLabel>
            <CustomTextField onChange={handleChange} size="small" required id="password" name="password" placeholder='Password' fullWidth
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={styles.icon}
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                  </IconButton>
                )
              }} className={styles.input} />
            <Box sx={{ pl: 0.5, mb: 4 }} className={errors.password ? `${styles.pwhelperError}` : ''}>
              Password must include
              <ul>
                <li>at least 8 characters</li>
                <li>a lowercase letter</li>
                <li>one capital letter</li>
                <li>and one number</li>
              </ul>
            </Box>

            <InputLabel error={errors.firstName} sx={{ mb: 1 }} htmlFor="firstName">First Name</InputLabel>
            <CustomTextField error={errors.firstName} helperText={errors.firstName ? 'Requires a valid name' : ''} onChange={handleChange} size="small" sx={{ mb: 2 }} required id="firstName" name="firstName" placeholder='First Name' fullWidth className={styles.input} />

            <InputLabel error={errors.lastName} sx={{ mb: 1 }} htmlFor="lastName">Last Name </InputLabel>
            <CustomTextField error={errors.lastName} helperText={errors.lastName ? 'Requires a valid name' : ''} onChange={handleChange} size="small" sx={{ mb: 2 }} required id="lastName" name="lastName" placeholder='Last Name' fullWidth className={styles.input} />

            {/* <InputLabel
              error={errors.phoneNumber}
              sx={{ mb: 1 }}
              htmlFor="phoneNumber"
            >
              Phone Number
            </InputLabel>
            <CustomTextField error={errors.phoneNumber} helperText={errors.phoneNumber ? 'Requires a valid number' : ''}
              onChange={handleChange} size="small"
              required id="phoneNumber"
              name="phoneNumber"
              fullWidth className={styles.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: '#909094 !important' }}>
                    +65
                  </InputAdornment>
                ),
              }}
            /> */}
            <Box sx={{ width: { xs: '100%', md: '100%' } }}>
              <InputLabel>
                Phone Number <span className='optional'></span>
              </InputLabel>

              <Grid container>
                <Grid item xs={3} md={2}>
                  <CustomTextField
                    error={errors.countryCode}
                    helperText={errors.countryCode ? 'Requires a valid country code' : ''}
                    sx={{ width: { xs: '100%' }, mb: 4 }}
                    value={registerForm.countryCode}
                    onChange={handleChange}
                    id='countryCode'
                    name='countryCode'
                    size='small'
                    type=''
                    className={styles.input}
                    placeholder='65'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>+</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={9} md={10}
                  sx={{
                    textAlign: 'end'
                  }}
                >
                  <CustomTextField
                    error={errors.phoneNumber}
                    helperText={errors.phoneNumber ? 'Requires a valid phone number' : ''}
                    sx={{
                      width: { xs: '100%', md: '98%' }, mb: 4,
                    }}
                    value={registerForm.phoneNumber}
                    onChange={handleChange}
                    id='phoneNumber'
                    name='phoneNumber'
                    size='small'
                    className={styles.input}
                    placeholder='Phone number'
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ display: 'flex', pt: 2 }}>
              <Checkbox
                icon={<Icon />}
                checkedIcon={<CheckedIcon />}
                onChange={(event) => handleChangeCheckbox('PDPAchecked', event.target.checked)}
                color="primary"
                name="PDPAchecked"
                checked={pdpaChecked}
                inputProps={{
                  'aria-labelledby': 'pdpaContent',
                }}
                sx={{ pl: 0 }}
              />
              <InputLabel sx={{ display: 'flex', alignItems: 'center', height: 'unset' }}>
                By submitting this form, I agree to terms stated in the PDPA statement
                <IconButton onClick={() => { setAgreeModal(true) }}>
                  <img src={'/images/icons/info_gray.svg'} alt="info" />
                </IconButton>
              </InputLabel>
            </Box>
            {errors.PDPAchecked && (
              <div style={{ color: 'rgb(211, 47, 47)', fontSize: '14px' }}>
                Requires acceptance of PDPA Content as true.
              </div>
            )}

            <Grid item xs={12} sx={{ pt: 1, pb: 2 }}>
              {
                registerFail.error ? <div className={styles.error}>{registerFail.message}</div> : <div style={{ height: 5 }}></div>
              }
            </Grid>

            <Button fullWidth variant="contained" className="btnSecondary" loading={loading} onClick={handleRegister}>Register</Button>
            <Grid container spacing={2}>
              <Grid item sx={{ mt: 4 }}>
                <div className={styles.text}>Already have an account? <Link className='link' sx={{ ml: 1, textDecorationColor: 'var(--TLC-gray)' }} onClick={() => onClick(1)}> Login</Link></div>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>

      <Modal open={agreeModal} onClose={handleCloseAgreeModal}>
        <Box className={styles.agreeModal} sx={{ p: 4 }}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Typography component={'h2'} sx={{ fontSize: '32px', fontWeight: 700 }}>
                About PDPA
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component={'span'} sx={{ fontSize: '16px' }}>
                By submitting this Form, you hereby agree that TLC may collect, obtain, store and process your personal data that you provide in this form for the purpose of receiving updates, news, promotional and marketing mails or materials from TLC. <br />
                <br />
                You hereby give your consent to TLC to: <br />
                <ol style={{ paddingLeft: '20px' }}>
                  <li>Store and process your Personal Data.</li>
                  <li>Disclose your Personal Data to the relevant governmental authorities or third parties where required by law or for legal purposes. </li>
                  <li>Contact you to provide you with information on any updates, news, promotional and marketing mails or materials via the following mode of communications: postal mail to your address(es) and/or electronic transmission to your email address(es); to your Singapore telephone number(s) that you have provided or may provide to TLC. </li>
                  <li>You hereby confirm that you are the user and/or subscriber of the telephone number(s) that you have provided or may provide to TLC. </li>
                  <li>For the avoidance of doubt, Personal Data includes all data defined within the Singapore Personal Data Protection Act 2012 including all data you had disclosed to TLC in this Form.</li>
                </ol>
              </Typography>
            </Grid>
            <Grid item xs={12} justifyContent={'center'} display={'flex'}>
              <Button className='btnPrimary' sx={{ width: '320px' }} onClick={() => { handleCloseAgreeModal() }}>Agree</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal open={confirmModal} onClose={handleCloseConfirmModal}>
        <Box className={styles.confirmModal} sx={{ p: 4 }}>
          <Grid container spacing={2} sx={{ mt: 4 }}>
            <Grid item xs={12} justifyContent={'center'} display={'flex'}>
              <img src={`/images/icons/check_green.svg`} alt="img" loading="lazy" />
            </Grid>
            <Grid item xs={12} justifyContent={'center'} display={'flex'} sx={{ fontSize: '18px' }}>
              Registered Successfully
            </Grid>
            <Grid item xs={12}>
              <Button className='btnPrimary' fullWidth onClick={() => { handleBackendLogin() }}>Ok</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>

  )
}

export default RegisterForm