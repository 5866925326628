import { restApi } from '../../api/restApi'
import { dataRsvp } from '../../types';
import { setdataRsvp } from './rsvpSlice';

type rsvpdataResponse = dataRsvp[]

export const rsvpApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    InsertUpdateRsvp: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/funeral/rsvp/submit',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log('dataInsertUpdate : ',data)
        } catch (error) {}
      },
    }),
    //GetRsvp: builder.mutation({
    GetRsvp: builder.query<rsvpdataResponse,string|undefined>({      
      query: (id) => ({
        url: `/api/app-template/funeral/rsvp/${id}`,
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setdataRsvp(data))
        } catch (error) {}
      },
    }),
  }),
})

export const { useGetRsvpQuery, useInsertUpdateRsvpMutation } = rsvpApi